<template>
  <p :class="item.classes"  >
    {{ item.text }}
  </p>
</template>

<script>
export default {
  props: ["status", "noColor"],
  inject: ["content"],
  computed: {
    item() {
      let status = {
        text: "",
        classes: "",
      };

      switch (this.status) {
        case "pending":
          status = {
            text: this.content.pending,
            classes: "text-pending",
          };
          break;
        case "inprogress":
          status = {
            text: this.content.beingPrepared,
            classes: "text-beingPrepared",
          };
          break;
        case "booked":
          status = {
            text: this.content.beingPrepared,
            classes: "text-secondaryColor",
          };
          break;
        case "accepted":
          status = {
            text: this.content.accepted,
            classes: "text-accepted",
          };
          break;
        case "rejected":
          status = {
            text: this.content.rejected,
            classes: "text-rejected",
          };
          break;
        case "canceled":
          status = {
            text: this.content.cancelled,
            classes: "text-cancelled",
          };
          break;
        case "completed":
          status = {
            text: this.content.completed,
            classes: "text-completed",
          };
          break;
        case "delivery":
          status = {
            text: this.content.beingDelivered,
            classes: "text-beingDelivered",
          };
          break;
        case "pickup":
          status = {
            text: this.content.readyForPickup,
            classes: "text-readyForPickup",
          };
          break;

        default:
          break;
      }

      if (this.noColor) {
        status.classes = "";
      }

      return status;
    },
  },
};
</script>