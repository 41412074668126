<template>
  <!-- <PaymentModal v-if="modalVisibility" @close="closeModal" @submit="acceptPrice" /> -->

  <div class="layout-padding" v-if="order">
    <PageTitle
      class="my-8"
      :title="content.order + ' #' + format(id)"
      :childOne="content.account"
      :childTwo="content.orderHistory"
    />
    <div class="my-5 grid grid-cols-12 gap-x-0 md:gap-x-10">
      <div class="col-span-12 lg:col-span-8 mb-12">
        <div
          class="
            flex flex-row
            items-center
            justify-between
            flex-wrap
            font-bold
            text-lg
          "
        >
          <p>
            {{ content.personalizedCandleRequest }}
          </p>
          <p>
            {{ order.price ? `${content.price}: ${format(order.price)}` : "" }}
          </p>
        </div>
        <div
          class="
            flex flex-row
            items-center
            justify-between
            flex-wrap
            font-bold
            mt-2
            mb-2
          "
        >
          <p>
            {{ date }}
          </p>
          <p>
            <OrderStatus :status="order.status" />
          </p>
        </div>
        <div
          class="flex flex-row items-center flex-wrap font-bold"
          :class="
            order.price && !order.accepted ? 'justify-between' : 'justify-start'
          "
        >
          <p class="font-bold">{{ content.description }}</p>
        </div>
        <p class="my-3">{{ order.description }}</p>
        <p class="font-bold my-3" v-if="order?.file">
          {{ content.attachment }}
        </p>
        <div
          v-if="order?.file"
          class="
            w-full
            grid grid-cols-1
            sm:grid-cols-2
            rounded-md
            border border-grayColor border-opacity-20
            p-3
          "
        >
          <!-- <p class="col-span-1">FILENAME</p> -->
          <p class="col-span-1 font-semibold">{{ type }}</p>
          <a
            :href="order.file"
            target="_blank"
            class="col-span-1 text-bluePrimary font-semibold"
          >
            {{ content.download }}
          </a>
        </div>
      </div>
      <div class="col-span-12 lg:col-span-4">
        <OrderSummery
          :status="order.status"
          :date="order.date"
          :subTotal="order.price"
          :accepted="order.accepted"
          :delivery="order.city?.price ?? 0"
          :total="total"
          :paid="order.paid"
          :acceptBtn="true" @pay="acceptPrice"
        >
        </OrderSummery>
        <OrderShipping :order="order" />
      </div>
    </div>
  </div>
</template>

<script>
import OrderSummery from "/src/components/ui/Order/OrderSummery.vue";
import OrderShipping from "../../../components/ui/Order/OrderShipping.vue";
import OrderStatus from "../../../components/ui/Order/OrderStatus.vue";
import { format } from "/src/assets/numberFormat";

// import PaymentModal from "../../../components/ui/PaymentModal.vue";

export default {
  components: {
    OrderSummery,
    OrderShipping,
    OrderStatus,
    // PaymentModal,
  },
  props: ["id"],
  inject: ["content"],
  data() {
    return {
      modalVisibility: false,
    };
  },
  async mounted() {
    await this.$store.dispatch("personalized/requests");

    if (!this.$store.getters["personalized/request"](this.id)) {
      this.$router.replace("/account/orderHistory");
    }
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    order() {
      return this.$store.getters["personalized/request"](this.id);
      // let order = 'products'

      // return order;
    },
    date() {
      return `${this.order.date.split(",")[2]}/${
        this.order.date.split(",")[1]
      }/${this.order.date.split(",")[0]}`;
    },
    type() {
      let type = this.content.image;

      let fileType = this.order.file.split(".").pop();
      if (fileType == "pdf") type = "PDF";
      if (fileType.includes("doc")) type = this.content.document_;

      return type;
    },
    total() {
      let total = 0;

      if (this.order.price) total += parseFloat(this.order.price);
      if (this.order.city) total += parseFloat(this.order.city.price);

      return total;
    },
  },
  methods: {
    format(number) {
      return format(number);
    },
    // openModal(){
    //   this.modalVisibility = true;
    // },
    // closeModal(){
    //   this.modalVisibility = false;
    // },
    async acceptPrice(method) {
      try {
        await this.$store.dispatch("personalized/accept", {
          id: this.id,
          method: method,
        });
        this.$store.dispatch("feedback/setFeedback", {
          type: "success",
          title: this.content.success,
          message: this.content.priceAccepted,
        });
        this.$router.replace("/account/orderhistory");
      } catch (error) {
        this.$store.dispatch("feedback/setFeedback", {
          type: "fail",
          title: this.content.fail,
          message: this.content.errorOccured,
        });
      }
    },
  },
};
</script>
