<template>
  <PaymentModal v-if="modalVisibility" @close="closeModal" @submit="pay" />

  <div class="order-summery-card pb-6">
    <div>
      <div class="text-center text-bluePrimary pt-6 lora-bold">
        {{ content.orderSummery }}
      </div>
      <div class="mt-6 flex items-center justify-between px-4 xl:px-5">
        <div>{{ content.status }}:</div>
        <div>
          <OrderStatus :status="status" :noColor="true" />
        </div>
      </div>
      <div class="mt-3 flex items-center justify-between px-4 xl:px-5">
        <div>{{ content.paid }}:</div>
        <div>
          <p class="capitalize">{{ paid ? content.yes : content.no }}</p>
        </div>
      </div>
      <div class="mt-3 flex items-center justify-between px-4 xl:px-5">
        <div>{{ content.orderDate }}:</div>
        <div>{{ formatedDate }}</div>
      </div>
      <hr style="color: #37328b22" class="my-4" />

      <slot></slot>
      <div class="flex justify-between px-4 xl:px-5 mt-4">
        <div class="ms-16 w-24 text-align-end">{{ content.subtotal }}:</div>
        <div class="text-align-end">
          {{ subTotal ? `${format(subTotal)} ${content.iqd}` : "" }}
        </div>
      </div>

      <div class="flex justify-between px-4 xl:px-5 mt-2">
        <div class="ms-16 w-24 text-align-end whitespace-nowrap">
          {{ content.deliveryCost }}:
        </div>
        <div class="text-align-end">
          {{ delivery || delivery == 0 ? `${format(delivery)} ${content.iqd}` : "" }}
        </div>
      </div>
      <div class="flex justify-between px-4 xl:px-5 mt-4 text-size-20">
        <div class="ms-16 ps-1 whitespace-nowrap">{{ content.total }}:</div>
        <div class="text-align-end whitespace-nowrap">
          {{ total ? `${format(total)} ${content.iqd}` : "" }}
        </div>
      </div>
      <div class="mx-3 my-4 py-4">
        <button
          v-if="acceptBtn && subTotal && !accepted"
          class="next-tab-button header-btn w-full hover:text-bluePrimary text-white bg-bluePrimary lora-bold mb-4 whitespace-nowrap hover:bg-blueSecondary duration-300"
          @click="openModal()"
        >
          {{ content.acceptPrice }}
        </button>

        <button
          v-if=" auth&&!paid && $route.name != 'personalizedOrderDetails'"
          class="alert-btn text-text-white text-white lora-bold whitespace-nowrap bg-secondaryColor duration-300 w-full hover:text-secondaryColor hover:bg-btnColor"
          @click="openModal"
        >
          {{ content.payNow }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "/src/assets/numberFormat";
import OrderStatus from "./OrderStatus.vue";

import PaymentModal from "../PaymentModal.vue";

export default {
  components: { OrderStatus, PaymentModal },
  inject: ["content"],
  emits: ["pay", "AcceptPrice"],
  props: [
    "paid",
    "status",
    "date",
    "subTotal",
    "delivery",
    "total",
    "acceptBtn",
    "accepted",
  ],
  data() {
    return {
      modalVisibility: false,
    };
  },
  computed: {
    auth() {
      return this.$store.getters["auth/auth"];
    },
    formatedDate() {
      return `${this.date?.split(",")[2]}/${this.date?.split(",")[1]}/${
        this.date?.split(",")[0]
      }`;
    },
  },
  methods: {
    format(number) {
      return format(number);
    },
    openModal() {
      this.modalVisibility = true;
    },
    closeModal() {
      this.modalVisibility = false;
    },
    pay(method) {
      // this.closeModal();
      this.$emit("pay", method);
      this.closeModal();
    },
  },
};
</script>
