<template>
  <div class="mt-16">
    <div class="page-title mb-8">
      {{ order.sellingPoint ? content.pickupInfo : content.shippingInformation }}
    </div>
    <div class="grid grid-cols-7 mb-4">
      <div class="col-span-2">{{ content.email }}</div>
      <div class="col-span-5">{{ user?.email }}</div>
    </div>
    <div class="grid grid-cols-7 mb-4">
      <div class="col-span-2">{{ content.phone }}</div>
      <div class="col-span-5">{{ user?.phone }}</div>
    </div>
    <div class="grid grid-cols-7 mb-4" v-if="order.city">
      <div class="col-span-2">{{ content.city }}</div>
      <div class="col-span-5">{{ order.city.name }}</div>
    </div>
    <div class="grid grid-cols-7 mb-4" v-if="order.address">
      <div class="col-span-2">{{ content.address }}</div>
      <div class="col-span-5">{{ order.address }}</div>
    </div>
    <div class="grid grid-cols-7 mb-4" v-if="order.sellingPoint">
      <div class="col-span-2">{{ content.pickupLocation }}</div>
      <div class="col-span-5">{{ order.sellingPoint.title }}</div>
    </div>
    <div class="grid grid-cols-7 mb-4">
      <div class="col-span-2">{{ content.notes }}</div>
      <div class="col-span-5">
        {{ order.notes ?? "" }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["content"],
  props: ["order"],
  computed: {
    user() {
      return this.order?.is_guest?this.order?.guest: this.$store.getters["user/user"]
    },
  },
};
</script>
